import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
//-----------------------------------------------
const StoreLanguage = localStorage.getItem('language');

i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: StoreLanguage ?? 'en',
        debug: false,
        backend: {
            loadPath: '/locales/{{lng}}/translation.json'
        },
        interpolation: {
            escapeValue: false // React already escapes values to prevent XSS
        }
    });
//------------------------------------------------
export default i18n;
