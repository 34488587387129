import React from 'react';
import css from './home.module.css';
import Image from 'react-bootstrap/Image';
//----------------------------------------------------

const Features = (props) => {
    const uSweight = window.innerWidth;

    return (<>
        <div className={uSweight < 1000 ? css.FeaturesRow_small : css.FeaturesRow}>
            <div className={css.FeaturesText}>
                <div style={{color: '#aa8bd0'}}>{props.t('t-text-features1')}</div>
                <div style={{fontSize: uSweight > 1300 ? '2em' : '1.5em'}}>{props.t('t-text-features2')}</div>
                <div style={{fontSize: uSweight > 1300 ? '1.5em' : '1em'}}>{props.t('t-text-features3')}</div>

            </div>
            <div className={uSweight < 1000 ? css.FeaturesBlock_small : css.FeaturesBlock}>
                <div className={css.FeaturesBlock1}>
                    <Image src="Features/features1.png" alt="nothing" width={50} style={{margin: 10}}/>
                    <div style={{
                        fontSize: uSweight < 1000 ? '2.5vw' : '1.2vw',
                        margin: 10
                    }}>{props.t('t-text-features4')}</div>
                    <div style={{
                        fontSize: uSweight < 1000 ? '2vw' : '1vw',
                        marginLeft: 10,
                        marginRight: 10,
                        color: '#aa8bd0'
                    }}>
                        {props.t('t-text-features5')}
                    </div>
                </div>
                <div className={css.FeaturesBlock1}>
                    <Image src="Features/features2.png" alt="nothing" width={50} style={{margin: 10}}/>
                    <div style={{
                        fontSize: uSweight < 1000 ? '2.5vw' : '1.2vw',
                        margin: 10
                    }}>{props.t('t-text-features6')}</div>
                    <div style={{
                        fontSize: uSweight < 1000 ? '2vw' : '1vw',
                        marginLeft: 10,
                        marginRight: 10,
                        color: '#aa8bd0'
                    }}>
                        {props.t('t-text-features7')}
                    </div>
                </div>
                <div className={css.FeaturesBlock1}>
                    <Image src="Features/features3.png" alt="nothing" width={50} style={{margin: 10}}/>
                    <div style={{
                        fontSize: uSweight < 1000 ? '2.5vw' : '1.2vw',
                        margin: 10
                    }}>{props.t('t-text-features8')}</div>
                    <div style={{
                        fontSize: uSweight < 1000 ? '2vw' : '1vw',
                        marginLeft: 10,
                        marginRight: 10,
                        color: '#aa8bd0'
                    }}>
                        {props.t('t-text-features9')}
                    </div>
                </div>
            </div>
        </div>
        <div className={uSweight < 1000 ? css.FeaturesRow_small : css.FeaturesRow}>
            {uSweight > 1300 && <div className={css.TradeBlock1}>
                <Image src="Trade/macbook.png" alt="nothing" style={{margin: 20, width: '90%'}}/>
            </div>}
            <div className={css.TradeBlock1}>
                <div style={{fontSize: uSweight > 1300 ? '2em' : '1.5em'}}>{props.t('t-text-trade1')}</div>
                <div style={{fontSize: uSweight > 1300 ? '1.5em' : '1em'}}>{props.t('t-text-trade2')}</div>
                <div className={css.TradeBlock2}>
                    <div>
                        <Image src="Trade/windows.svg" alt="nothing"
                               className={uSweight < 1000 ? css.trade_small : css.trade}/>
                    </div>
                    <div>
                        <Image src="Trade/mac.svg" alt="nothing"
                               className={uSweight < 1000 ? css.trade_small : css.trade}/>
                    </div>
                    <div>
                        <Image src="Trade/android.svg" alt="nothing"
                               className={uSweight < 1000 ? css.trade_small : css.trade}/>
                    </div>
                    <div ref={props.ref_exchange}>
                        <Image src="Trade/appstore.svg" alt="nothing"
                               className={uSweight < 1000 ? css.trade_small : css.trade}/>
                    </div>
                </div>

            </div>

        </div>
    </>)
}

export default Features;
