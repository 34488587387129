import React, {useRef} from 'react';
import css from './login.module.css'
///////////////////////////////////////////////////////////////////////
import HeaderComponent from "../header/header";
import HomeContainer from "../home/home_container";

///////////////////////////////////////////////////////////////////////
const Login = (props) => {
    let ref_home = useRef(null);
    let ref_features = useRef(null);
    let ref_exchange = useRef(null);
    let ref_faq = useRef(null);
    //-------------------------------------------------------
    const handleScroll = (ref) => {
        if (ref_features && ref_features.current) {
            ref.current.scrollIntoView({behavior: 'smooth'})
        }
    };

    //-------------------------------------------------------
    //-------------------------------------------------------

    //---------------------------------------------------------------
    return (<div ref={ref_home} className={css.globalLogin}>
        <div className={css.header}>
            <HeaderComponent
                ref_home={ref_home}
                ref_features={ref_features}
                ref_exchange={ref_exchange}
                ref_faq={ref_faq}
                handleScroll={handleScroll}
                {...props}
            />
        </div>

        <HomeContainer
            ref_home={ref_home}
            ref_features={ref_features}
            ref_exchange={ref_exchange}
            ref_faq={ref_faq}
            handleScroll={handleScroll}
        />
    </div>)
};
//*******************************
export default Login
