import React from 'react';
import css from './home.module.css';
import Image from 'react-bootstrap/Image';
import {CryptoCurrencyMarket} from "react-ts-tradingview-widgets";
///////////////////////////////////////////////////////////////////////

//---------------------------------------------------------------------------------------
const Exchange = (props) => {
    const uSweight = window.innerWidth;
    return (
        <>
            <div className={uSweight < 1000 ? css.globalExchange_small : css.globalExchange}>
                <div style={{fontSize: 'x-large', margin: 20, paddingRight: '25vw'}}>{props.t('t-text-exchange1')}</div>
                <div className={css.CryptoCurrencyMarket}>
                    <CryptoCurrencyMarket
                        colorTheme="dark"
                        width="100%"
                        height={400}
                        locale={props.language_s}
                        isTransparent={true}
                    />
                </div>
            </div>
            <div ref={props.ref_faq}>
                <Image src="Untitled.svg" alt="nothing" style={{marginLeft: '5vw', width: '90vw'}}/>
                <Image src="ellpse.png" alt="nothing" style={{marginLeft: '5vw', width: '90vw'}}/>
            </div>

        </>
    )
}
//---------------------------------------------------------
export default Exchange;
