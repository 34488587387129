import React from 'react';
import Nav from 'react-bootstrap/Nav';
import css from "./home.module.css";
import {Link} from "react-router-dom";
import Image from 'react-bootstrap/Image';
//-----------------------------------------------------------
const socialLinks = [
    {imgsrc: '/Footer/insta.svg', href: "https://instagram.com/"},
    {imgsrc: '/Footer/dribble.svg', href: "https://dribble.com/"},
    {imgsrc: '/Footer/twitter.svg', href: "https://twitter.com/"},
    {imgsrc: '/Footer/youtube.svg', href: "https://youtube.com/"},
]
//-----------------------------------------------------------
const FooterComponent = (props) => {
    //console.log('Header ', props)
    const uSweight = window.innerWidth;
    //-------------------------------------------------------

    return (<>
            <div className={uSweight < 1000 ? css.globalFooter_small : css.globalFooter}>
                <div className={css.logoFooter}>
                    <div>
                        <img style={{width: 40, marginLeft: 10}} src='logo1.png' alt="logo"/>
                        <span style={{marginLeft: 10, color: '#acacac', fontSize: '1.5rem'}}>Cryptocurrency Eye</span>
                    </div>
                    <div style={{marginTop: 20, width: '90%'}}>{props.t('t-text-footer3')}</div>
                    <div className={css.socialLinksFooter}>
                        {socialLinks.map((items, i) => (
                            <Link style={{marginRight: 10}} to={items.href} key={i}><img src={items.imgsrc}
                                                                                         alt={items.imgsrc}
                                                                                         className='footer-icons'/></Link>
                        ))}
                    </div>
                </div>
                <div className={css.linkFooter}>
                    <div style={{
                        fontSize: 'x-large',
                        marginLeft: 10,
                        marginBottom: 20
                    }}>{props.t('t-text-footer1')}</div>

                    <Nav bg="myColor" data-bs-theme="dark" className="flex-column">
                        <Nav.Link onClick={() => props.handleScroll(props.ref_home)}>{props.t('t-menu-home')}</Nav.Link>
                        <Nav.Link
                            onClick={() => props.handleScroll(props.ref_features)}>{props.t('t-menu-features')}</Nav.Link>
                        <Nav.Link
                            onClick={() => props.handleScroll(props.ref_exchange)}>{props.t('t-menu-exchange')}</Nav.Link>
                        <Nav.Link onClick={() => props.handleScroll(props.ref_faq)}>{props.t('t-menu-faq')}</Nav.Link>
                    </Nav>

                </div>
                <div className={css.contactFooter}>
                    <div style={{fontSize: 'x-large'}}>{props.t('t-text-footer2')}</div>
                    <div style={{marginLeft: 10}}>
                        {/*<div style={{marginTop: 20}}><Image style={{marginRight: 20}}
                                                            src={'/Footer/number.svg'} alt="number-icon" width={20}
                                                            height={20}/>+49(0)7843-94-76-70
                        </div>*/}
                        <div style={{marginTop: 20}}><Image style={{marginRight: 20}}
                                                            src={'/Footer/email.svg'} alt="email-icon" width={20}
                                                            height={20}/>info@okocrypt.com
                        </div>
                        <div style={{marginTop: 20}}><Image style={{marginRight: 20}}
                                                            src={'/Footer/address.svg'} alt="address-icon" width={20}
                                                            height={20}/>6391 Elgin St. Celina, Delaware
                        </div>

                    </div>
                </div>
            </div>
        </>
    )


};
//----------------------
export default FooterComponent;
