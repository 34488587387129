import axios from 'axios';

// -------------------------------------------
const inst_axios = axios.create({
  method: 'HEAD',
  mode: "cors",
  //mode: 'no-cors',
  withCredentials: false,
  crossorigin: false,
  maxBodyLength: Infinity,
  baseURL:
    'https://te1-0-7-1-rcr21-ams08-atlas-cogentco.online:45802/api37kyg65dk86j73jdf77apipg45d-dftrog735hd/hjfj3256/apiu/',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
  },
});

//**********************************************
export default inst_axios;
