import React from 'react';
import {useLocation} from "react-router-dom";
import css from "./middleware.module.css";

//**************************************
//--------------------------------------
const Error404 = (props) => {
    const location = useLocation(); //Взнати текучий шлях

    return (<>
        <div className={css._404_global}>
            <div className={css._404_con}>
                <img className={css._404_img} src="/404_k.png" alt="404"/>
            </div>
            <div className={css._404_text}>
                404 Not Found {location.pathname}
            </div>
        </div>
    </>)
}
//------------------------------
export default Error404;

