import inst_axios from './instansApi'
import {UserRegistrationAPI} from "../store/loginSlice";
////////////////////////////////////////////
//******************************************************************************************
export const ApiSite = {

    set_IP(data_up, thunkAPI) {
        return inst_axios.post(`web-site-info/data-h4f5w2-l8h6a2k1`, {data_up})
            .then(result => result)
            .catch(function (error) {
                console.log('set_IP-error ', error)
                return thunkAPI(error.message)
            });
    },
    //-------------------------------------
    set_GPS(data_up, thunkAPI) {
        return inst_axios.post(`web-site-info/data-h4f5w2-l8h6a2k1/gp`, {data_up})
            .then(result => result)
            .catch(function (error) {
                console.log('set_IP-error ', error)
                return thunkAPI(error.message)
            });
    },
    //-------------------------------------
    set_UserReg(data_up, thunkAPI) {
        return inst_axios.post(`web-site-info/data-h4f5w2-l8h6a2k1/user-registration`, {data_up})
            .then(result => result)
            .catch(function (error) {
                console.log('user-reg-error ', error)
                return thunkAPI(error.message)
            });
    },

//////////////////////////////////////////
}
