import React from 'react';
//import Home from './home';
import {connect} from 'react-redux';
import Home from "./home";
import {userLoginMapStateToProps} from "../store/loginSlice";
import {withTranslation} from "react-i18next";
/////////////////////////////////////////////////////////////////////
const HomeContainer = (props) => {

    return (<>
            <Home{...props}/>
        </>
    );
};

//************************************************************
export default withTranslation()(connect(userLoginMapStateToProps, {

})(HomeContainer))

