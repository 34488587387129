import React from 'react';
import css from './home.module.css';
import Banner from "./banner";
import Features from "./features";
import Exchange from "./exchange";
import Faq from "./faq";
import Footer from "./footer";
///////////////////////////////////////////////////////////////////////
const Home = (props) => {

    //-------------------------------------------------------
    return (<>

        <div className={css.HomeBlock}>
            <Banner ref_features={props.ref_features} {...props} />
            <Features ref_exchange={props.ref_exchange} {...props} />
            <Exchange ref_faq={props.ref_faq} {...props} />
            <Faq {...props} />
            <Footer
                ref_home={props.ref_home}
                ref_features={props.ref_features}
                ref_exchange={props.ref_exchange}
                ref_faq={props.ref_faq}
                handleScroll={props.handleScroll}
                {...props}
            />
            <div className={css.HomeFooter}>
                <div>@{(new Date().getFullYear())}{' - '}{props.t('t-text-footer7')}</div>
            </div>
        </div>
    </>)
};

//*******************************
export default Home
