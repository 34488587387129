import React from 'react';
import css from './home.module.css';
import Image from 'react-bootstrap/Image';
//import {CryptoCurrencyMarket} from "react-ts-tradingview-widgets";
import {Ticker} from "react-ts-tradingview-widgets";
///////////////////////////////////////////////////////////////////////
const Banner = (props) => {
    const uSweight = window.innerWidth;
    return (<>
            <div className={css.BannerRow}>
                <div className={uSweight > 1300 ? css.text_banner : css.text_banner_small}>
                    <div>
                        {props.t('t-text-global1')}
                    </div>
                    <div>
                        {props.t('t-text-global2')}
                    </div>
                </div>
                <div className={ uSweight > 1300 ? css.text_banner2 : css.text_banner2_small}>
                    <div>
                        {props.t('t-text-global3')}
                    </div>
                    <div>
                        {props.t('t-text-global4')}
                    </div>

                </div>
                <div className={css.arrow}>
                    <Image src="images/arrow.svg" alt="nothing" style={{width: '10vw'}}/>
                </div>
                <div className={css.arrow1}>
                    <Image src="images/arrow.svg" alt="nothing" style={{width: '10vw'}}/>
                </div>
                <div className={css.arrow2}>
                    <Image src="images/arrow.svg" alt="nothing" style={{width: '10vw'}}/>
                </div>
                <div className={css.arrow3}>
                    <Image src="images/arrow.svg" alt="nothing" style={{width: '10vw'}}/>
                </div>

                {uSweight > 1300 && <div className={css.HomeBanner}>
                    <Image src="banner1.png" alt="nothing" style={{width: '34vw'}}/>
                </div>}

                <div ref={props.ref_features} className={css.widgets1}>
                    <Ticker
                        colorTheme="dark"
                        width="80%"
                        locale={props.language_s}
                        isTransparent={true}
                        symbols={[
                            {
                                "proName": "BITSTAMP:BTCUSD",
                                "title": "BTC/USD"
                            },
                            {
                                "proName": "BITSTAMP:ETHUSD",
                                "title": "ETH/USD"
                            },
                            {
                                "proName": "BITSTAMP:SOLUSD",
                                "title": "SOL/USD"
                            },
                            {
                                "proName": "FX_IDC:EURUSD",
                                "title": "EUR/USD"
                            },
                            {
                                "proName": "FOREXCOM:SPXUSD",
                                "title": "S&P 500"
                            },
                            {
                                "proName": "FOREXCOM:NSXUSD",
                                "title": "Nasdaq 100"
                            },

                        ]}
                    />
                </div>

            </div>
        </>
    )
};

//*******************************
export default Banner
