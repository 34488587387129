import './App.css';
import {ConfigProvider} from 'antd';
import {Routes, Route} from 'react-router-dom';
import Error404 from './middleware/404';
import LoginContainer from "./login/login_container";
//----------------------------------------------------
function App() {

    return (<div>
            <ConfigProvider>
                <Routes>
                    <Route path="/"
                           element={<LoginContainer/>}/>
                    {/*<Route path="/registration" element={<RegistrationContainerClient/>}>
                        <Route path=":userId" element={<RegistrationContainerClient/>}/>
                    </Route>*/}
                    <Route path="*" element={<Error404/>}/>
                </Routes>
            </ConfigProvider>
        </div>
    );
    return (
    <div className="App">
      <header className="App-header">

        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>

      </header>
    </div>
  );
}

export default App;
