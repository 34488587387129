import {configureStore} from '@reduxjs/toolkit'
import loginReducer from './loginSlice'

//------------------------------------------
export const store = configureStore({
    reducer: {
        login: loginReducer,
    },
    //middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

})
