import React from 'react';
import css from './home.module.css';
import Image from 'react-bootstrap/Image';
import Accordion from 'react-bootstrap/Accordion';
///////////////////////////////////////////////////////////////////////

//---------------------------------------------------------------------------------------
const Faq = (props) => {
    const uSweight = window.innerWidth;
    return (
        <>
            <div className={css.globalFaq}>
                <div style={{
                    fontSize: uSweight > 1300 ? 'xx-large' : 'x-large',
                    margin: 20,
                    paddingRight: uSweight > 1000 && '28vw'
                }}>{props.t('t-text-faq1')}</div>

                <div className={css.rowFaq}>
                    <div className={uSweight > 1300 ? css.faqAccordion : css.faqAccordion_small}>
                        <Accordion data-bs-theme="dark">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{props.t('t-text-faq2')}</Accordion.Header>
                                <Accordion.Body>{props.t('t-text-faq3')}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>{props.t('t-text-faq4')}</Accordion.Header>
                                <Accordion.Body>{props.t('t-text-faq5')}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>{props.t('t-text-faq6')}</Accordion.Header>
                                <Accordion.Body>{props.t('t-text-faq7')}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>{props.t('t-text-faq8')}</Accordion.Header>
                                <Accordion.Body>{props.t('t-text-faq9')}</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>{props.t('t-text-faq10')}</Accordion.Header>
                                <Accordion.Body>{props.t('t-text-faq11')}</Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    {uSweight > 1300 && <div>
                        <Image src="faq.png" alt="nothing" style={{marginLeft: '2vw', width: '30vw'}}/>
                    </div>}
                </div>
            </div>


        </>
    )
}
//---------------------------------------------------------
export default Faq;
