import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
//import {loginApi} from '../services/loginService'
import CryptoJS from 'crypto-js';
import {v4 as uuidv4} from 'uuid';
import {ApiSite} from "../services/Service";

//************************************************************
const cryptData = (text) => CryptoJS.AES.encrypt(text, process.env.REACT_APP_PAYLOAD_KEY).toString();
const cryptDataGPS = (text) => CryptoJS.AES.encrypt(text, process.env.REACT_APP_PAYLOAD_GPS).toString();
const localStorageSet = async (key, value) => localStorage.setItem(key, value);
//***************************| - Thunk - |***************************
//*******************************************************************
//-------------------------------------------------------------------------------------------------
export const LanguageSwitcher = createAsyncThunk(
    'login/LanguageSwitcher',
    async (language, {rejectWithValue}) => {
        //console.log('language ', language)
        await localStorageSet('language', language);
        return language
    }
)
//-------------------------------------------------------------------------------------------------
export const UserRegistrationAPI = createAsyncThunk(
    'login/UserRegistrationAPI',
    async ({
               email, firstName, lastName, password, phone, userInfo, uuid, latitude, longitude,
           }, {rejectWithValue}) => {

        const data_gps = await cryptDataGPS(JSON.stringify({
            latitude,
            longitude,
        }));
        const data_up = await cryptData(
            JSON.stringify({
                error: false,
                email,
                firstName,
                lastName,
                password: cryptDataGPS(password),
                phone,
                userInfo: {
                    city: userInfo.city,
                    country_name: userInfo.country_name,
                    ip: userInfo.ip,
                },
                uuid,
                key: '#%dg5R3s7K8dR4s@dn6Mh#khd',
                gps: data_gps
            }),
        );
        return (await ApiSite.set_UserReg(data_up, rejectWithValue));
    }
)

//-------------------------------------------------------------------------------------------------
export const GPSLoginAPI = createAsyncThunk(
    'login/GPSLoginAPI',
    async ({uuid, latitude, longitude}, {rejectWithValue}) => {
        const data_gps = await cryptDataGPS(JSON.stringify({
            latitude,
            longitude,
        }));
        const data_up = await cryptData(
            JSON.stringify({
                error: false,
                uuid,
                key: '#%dg5R3s7K8dR4s@dn6Mh#khd',
                gps: data_gps
            }),
        );
        return (await ApiSite.set_GPS(data_up, rejectWithValue));
    }
)

//-------------------------------------------------------------------------------------------------
export const IPLoginAPI = createAsyncThunk(
    'login/IPLoginAPI',
    async (userInfo, {rejectWithValue}) => {
        return new Promise(async (resolve) => {
            const StoreLogin = localStorage.getItem('uuid');

            if (!StoreLogin) {
                const uuid = await uuidv4();
                await localStorageSet('uuid', uuid);
                const data_up = cryptData(
                    JSON.stringify({
                        error: false,
                        uuid,
                        key: '#%dg5R3s7K8dR4s@dn6Mh#khd',
                        ip: userInfo.ip,
                        city: userInfo.city ?? '',
                        country: userInfo.country_name ?? '',
                    }),
                );

                const set_data_sql = await ApiSite.set_IP(data_up, rejectWithValue);
                //console.log('set_data_sql ', set_data_sql)
                resolve(set_data_sql)

            } else {
                const data_up = cryptData(
                    JSON.stringify({
                        error: false,
                        uuid: StoreLogin,
                        key: '#%dg5R3s7K8dR4s@dn6Mh#khd',
                        ip: userInfo.ip,
                        city: userInfo.city ?? '',
                        country: userInfo.country_name ?? '',
                    }),
                );
                const set_data_sql = await ApiSite.set_IP(data_up, rejectWithValue);
                //console.log('set_data_sql ', set_data_sql)
                resolve(set_data_sql)
            }


            resolve(null)
        });
    }
)

//-------------------------------------------------------------------------------------------------
//#################################################################################################
const initialState = {
    isAuth: false,
    language_s: localStorage.getItem('language') ?? 'en',
    uuid_client: '',
    user: {},
    loading: false,
    message: '',
    error: '',

}
//#################################################################################################
export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        isMessage: (state, action) => {
            state.message = action.payload;
        },
        isError: (state, action) => {
            state.error = action.payload;
        },
    },
    //**************************************************************
    extraReducers: (builder) => {
        builder
            .addCase(UserRegistrationAPI.pending, (state, action) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(UserRegistrationAPI.fulfilled, (state, action) => {
                if (!action.payload.data.error) {
                    state.error = '';
                } else {
                    state.error = action.payload.data.email;
                }
                state.loading = false;
            })
            .addCase(UserRegistrationAPI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            /////////////////////////////////////////////////////////////////////////////////
            .addCase(GPSLoginAPI.fulfilled, (state, action) => {
                //console.log('GPSLoginAPI-payload ', action.payload.data)

            })
            .addCase(GPSLoginAPI.rejected, (state, action) => {
                //state.error = action.payload;
            })
            /////////////////////////////////////////////////////////////////////////////////
            // - IPLoginAPI
            .addCase(IPLoginAPI.fulfilled, (state, action) => {
                if (!action.payload.data.error && action.payload.data.uuid) {
                    state.uuid_client = action.payload.data.uuid;
                }
            })
            .addCase(IPLoginAPI.rejected, (state, action) => {
                //state.error = action.payload;
            })
            /////////////////////////////////////////////////////////////////////////////////
            // - LanguageSwitcher
            .addCase(LanguageSwitcher.fulfilled, (state, action) => {
                state.language_s = action.payload;
            })

    }
})
// Selector ********************************************************
export const userLoginMapStateToProps = (state) => ({
    error: state.login.error,
    language_s: state.login.language_s,
    uuid_client: state.login.uuid_client,
    loading: state.login.loading,
    message: state.login.message,
    user: state.login.login,
});
//******************************************************************
// Action creators are generated for each case reducer function
export const {
    isMessage,
    isError,
} = loginSlice.actions
export default loginSlice.reducer
