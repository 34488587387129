import React, {useState} from 'react';
import css from './header.module.css';
//import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
//////////////////////////////////////////////////////////////////////////////
import LanguageIcon from '@mui/icons-material/Language';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
//-----------------------------------------------------------
import Modal from 'react-bootstrap/Modal';
import {Form, Field} from 'react-final-form'
import Alert from 'react-bootstrap/Alert';
import {Spinner} from "react-bootstrap";
import {useDispatch} from "react-redux";

//import PhoneInput from 'react-phone-number-input'
//-----------------------------------------------------------

const HeaderComponent = (props) => {
    //console.log('Header ', props)
    //-----------------------------------------------------------
    const dispatch = useDispatch();
    //-------------------------------------------------------
    const [showLogin, setShowLogin] = useState(false);
    const handleCloseLogin = () => setShowLogin(false);
    const handleShowLogin = () => setShowLogin(true);
    //-------------------------------------------------------
    const [showReg, setShowReg] = useState(false);
    const handleCloseReg = () => {
        setShowReg(false);
        dispatch(props.isError(''))
    };
    const handleShowReg = () => setShowReg(true);
    const uSweight = window.innerWidth;
    //-------------------------------------------------------

    return (<>
        {/*--------------------------------MODAL LOGIN----------------------------------*/}
        <div className={uSweight < 1000 ? css.modalLogin_small : css.modalLogin}>
            <Modal bg="myColor" data-bs-theme="dark" show={showLogin} onHide={handleCloseLogin} style={{

                marginTop: '20vh',
            }}>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{color: 'white'}}>{props.t('t-sign-in')}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{paddingBottom: 20}}>
                    <span style={{color: 'white'}}>{props.t('t-text-global4')}</span>
                </Modal.Body>
                {/*<Modal.Footer>
                        </Modal.Footer>*/}
            </Modal>
        </div>

        {/*--------------------------------MODAL REGISTRATION----------------------------------*/}
        <div className={uSweight < 1000 ? css.modalLogin_small : css.modalLogin}>
            <Modal bg="myColor" data-bs-theme="dark" show={showReg} onHide={handleCloseReg} style={{
                marginTop: '5vh',
            }}>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{color: 'white'}}>{props.t('t-sign-up')}</span></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <span style={{color: 'white', marginBottom: 10}}>{props.t('t-modal-registration1')}</span>

                    <Form
                        onSubmit={async (value) => {
                            const res = await props.onSubmitRegistration(value)
                            if (!res.error) {
                                dispatch(props.isMessage(props.t('t-modal-registration14') + ' (' +
                                    res.firstName + ' / ' + res.email + ')'));
                                handleCloseReg();
                                setTimeout(() => {
                                    dispatch(props.isMessage(''));
                                }, 7000)
                            }
                            //console.log('resUplUser ', res);
                        }}
                        /*initialValues={{remember_me: false,}}*/
                        render={({handleSubmit, form, submitting, pristine, values}) => (
                            <form className={css.formLogin} onSubmit={handleSubmit}>
                                {props.error && (<Alert variant='danger'>
                                    <b>{props.error} - {props.t('t-modal-registration13')} </b>
                                </Alert>)}
                                <div className="mb-3">
                                    <label className={css.formLogLabel}>*{props.t('t-modal-registration6')}</label>
                                    <Field
                                        className={css.formInput}
                                        name="firstName"
                                        component="input"
                                        type="text"
                                        placeholder={props.t('t-modal-registration7')}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className={css.formLogLabel}>{props.t('t-modal-registration8')}</label>
                                    <Field
                                        className={css.formInput}
                                        name="lastName"
                                        component="input"
                                        type="text"
                                        placeholder={props.t('t-modal-registration9')}
                                    />
                                </div>

                                <div className="mb-3">
                                    <label className={css.formLogLabel}>*{props.t('t-modal-registration10')}</label>
                                    <Field
                                        className={css.formInput}
                                        name="phone"
                                        component="input"
                                        type="phone"
                                        placeholder={props.t('t-modal-registration9')}
                                    />

                                    {/*<Field component={PhoneAdapter} name="phone"
                                               type="phone"
                                               placeholder={props.t('t-modal-registration9')}
                                        />*/}
                                </div>

                                <div className="mb-3">
                                    <label className={css.formLogLabel}>*{props.t('t-modal-registration4')}</label>
                                    <Field
                                        className={css.formInput}
                                        name="email"
                                        component="input"
                                        type="email"
                                        placeholder={props.t('t-modal-registration5')}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className={css.formLogLabel}>*{props.t('t-modal-registration2')}</label>
                                    <Field
                                        className={css.formInput}
                                        name="password"
                                        component="input"
                                        type="password"
                                        placeholder={props.t('t-modal-registration3')}
                                    />
                                </div>

                                <Button className={css.formLogButton}
                                        variant={props.loading ? "outline-light" : "outline-secondary"}
                                        type="submit"
                                        disabled={!(values.firstName && values.phone && values.email && values.password) || props.loading}>
                                    {props.loading ? (<>
                                        <Spinner
                                            className={css.formButtonSpinner}
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> <span>Loading...</span>
                                    </>) : (<>{props.t('t-modal-button-save')}</>)}

                                </Button>
                            </form>)}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <span style={{color: 'grey', fontSize: '0.9em'}}>{props.t('t-modal-registration12')}</span>
                </Modal.Footer>
            </Modal>
        </div>


        {/*-------------------------------------------------------------------------------------------------*/}
        <Navbar bg="myColor" data-bs-theme="dark" expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/">
                    <img style={{width: uSweight < 1000 ? 20 : 60, marginRight:  uSweight < 1000 ? 10 : 30,}} src='logo.png' alt="logo"/>
                    <span style={{
                        marginRight:  uSweight < 1000 ? 0 : 30, color: '#B22222', fontSize: uSweight < 500 ? '0.8em' : '1.2em'
                    }}>Connecting Crypto Opportunities</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav variant="underline" className="me-auto" defaultActiveKey="#home">
                        <Nav.Link
                            onClick={() => props.handleScroll(props.ref_home)}>{props.t('t-menu-home')}</Nav.Link>
                        <Nav.Link
                            onClick={() => props.handleScroll(props.ref_features)}>{props.t('t-menu-features')}</Nav.Link>
                        <Nav.Link
                            onClick={() => props.handleScroll(props.ref_exchange)}>{props.t('t-menu-exchange')}</Nav.Link>
                        <Nav.Link
                            onClick={() => props.handleScroll(props.ref_faq)}>{props.t('t-menu-faq')}</Nav.Link>

                    </Nav>

                    <Navbar.Collapse className="justify-content-end">
                        <Dropdown>
                            <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                <>
                                    <LanguageIcon style={{marginRight: 5}}/>
                                    <span>{props.t('t-language')}</span>
                                </>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {
                                    props.i18n.changeLanguage("en");
                                    props.LanguageSwitcher("en");
                                }}>{props.t('t-en')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    props.i18n.changeLanguage("ru");
                                    props.LanguageSwitcher("ru");
                                }}>{props.t('t-ru')}</Dropdown.Item>
                            </Dropdown.Menu>
                            <Button
                                variant="dark"
                                style={{marginLeft: 10}}
                                onClick={handleShowLogin}
                            >
                                <MeetingRoomIcon/>
                                <span>{props.t('t-sign-in')}</span>
                            </Button>
                            <Button
                                variant="dark"
                                style={{marginLeft: 5}}
                                onClick={handleShowReg}
                            >
                                <AssignmentIndIcon style={{marginRight: 5}}/>
                                <span>{props.t('t-sign-up')}</span>
                            </Button>
                        </Dropdown>
                    </Navbar.Collapse>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <div className={css.modalMessage}>{props.message && <Alert variant='success'>{props.message}</Alert>}</div>
    </>)


};
//----------------------
export default HeaderComponent;
