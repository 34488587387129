import React, {useContext, useEffect} from "react";
import {connect} from "react-redux";
import Login from "./login";
import {
    GPSLoginAPI,
    IPLoginAPI, isError, isMessage,
    LanguageSwitcher,
    userLoginMapStateToProps,
    UserRegistrationAPI
} from "../store/loginSlice";
import {useGeolocated} from "react-geolocated";
import {IPInfoContext} from "ip-info-react";
import {withTranslation} from 'react-i18next';
/////////////////////////////////////////////////////////////////////
const LoginContainer = (props) => {
    //console.log('LoginContainer ', props)
    //-----------------------------------
    const {IPLoginAPI, GPSLoginAPI, uuid_client, UserRegistrationAPI} = props
    //-----------------------------------
    const userInfo = useContext(IPInfoContext);
    const {coords, isGeolocationAvailable, isGeolocationEnabled} =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
    //------------------------------
    useEffect(() => {
        if (userInfo.ip) {
            IPLoginAPI(userInfo);
        }
    }, [userInfo.ip])

    //------------------------------
    useEffect(() => {
        if (uuid_client && isGeolocationAvailable && isGeolocationEnabled && coords) {
            GPSLoginAPI(
                {
                    uuid: uuid_client,
                    latitude: coords.latitude,
                    longitude: coords.longitude,
                });
        }
    }, [uuid_client, coords])

    //******************************
    const onSubmitRegistration = async (value) => {
       const resUplUser = await UserRegistrationAPI({
            email: value.email,
            firstName: value.firstName,
            lastName: value.lastName ?? null,
            password: value.password,
            phone: value.phone,
            userInfo: userInfo ?? null,
            uuid: uuid_client ?? null,
            latitude: coords? coords.latitude : null,
            longitude: coords ? coords.longitude : null,
        })
        return resUplUser.payload.data
    }
    //******************************
    return (
        <>
            <Login onSubmitRegistration={onSubmitRegistration} {...props}/>
        </>
    )
};
//*************************************************
export default withTranslation()(connect(userLoginMapStateToProps, {
    IPLoginAPI,
    GPSLoginAPI,
    LanguageSwitcher,
    UserRegistrationAPI,
    isMessage,
    isError,
})(LoginContainer));
