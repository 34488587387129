import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom';
import {store} from './store/index'
import IPInfo from 'ip-info-react';
//-----------------------------------------------------
import './i18n'; // Import the i18n configuration
//-----------------------------------------------------
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <IPInfo>
                <App/>
            </IPInfo>
        </Provider>
    </BrowserRouter>
);
/////////////////////////////////////////////////////////////////////////////////
reportWebVitals();
